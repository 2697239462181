import { Button, Form, Input } from 'antd';
import styles from './GroupForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { groupsApi } from '../groupsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import api from "../../../api/api";
import {AutocompleteSelect} from "../../../shared/AutocompleteSelect/AutocompleteSelect";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {DrawerCustom} from "../../../shared/Drawer/Drawer";

export const GroupForm = ({ group, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [selectedDirection, setSelectedDirection] = useState();
  const [isActive, setIsActive] = useState(
    group?.isActive !== undefined ? group?.isActive : true
  );
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const { TextArea } = Input;
  useEffect(() => {
    if (group) {
      form.setFieldsValue(group);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(group.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(group.updatedAt, user.timezone)
      );
      if (group?.direction?.id) {
        form.setFieldValue('direction', {
          label: `#${group.direction.id} ${group.direction.name}`,
          value: group.direction.id,
          key: group.direction.id,
        });
        setSelectedDirection({
          label: `#${group.direction.id} ${group.direction.name}`,
          value: group.direction.id,
          key: group.direction.id,
        });
      }
      setIsActive(group.isActive);
    }
  }, [group]);

  const fetchDirections = async (name) => {
    const idFilter =
        !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/direction/label?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchDirections = async (name) => {
    const res = await api.get(`/direction/label?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.OFFERS_EDIT) && (
        <DeleteButton entity={group} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(groupsApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue('name')?.trim(),
            type: form.getFieldValue('type')?.trim(),
            description: form.getFieldValue('description')?.trim(),
            directionId: selectedDirection?.value,
            isActive: isActive ? isActive : false,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="direction" label={f({id: 'direction'})}>
          <AutocompleteSelect
              className={styles.input}
              value={selectedDirection}
              placeholder={f({id: 'direction'})}
              initialFetch={initialFetchDirections}
              fetch={fetchDirections}
              onChange={setSelectedDirection}
          />
          {selectedDirection?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.OFFERS_EDIT} isShow={!!selectedDirection?.value}
                                                             onClick={() => {
                                                               setIsOpenDrawer(true)
                                                               setDataDrawer({id: selectedDirection?.value, type: 'direction'})
                                                             }}/>}
        </Form.Item>
        <Form.Item
          name="type"
          label={f({id: 'type'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'type'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="description" label={f({id:'description'})}>
          <TextArea
            rows={4}
            rootClassName={styles.input}
            style={{ resize: 'none', width: '350px' }}
            placeholder={f({id:'description'})}
          />
        </Form.Item>
        {group && (
          <Form.Item name="createdAt" label={f({id:'created'})}>
            <Input
              disabled
              placeholder={f({id:'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {group && (
          <Form.Item name="updatedAt" label={f({id:'updated'})}>
            <Input
              disabled
              placeholder={f({id:'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};
