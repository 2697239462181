import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import {App, Button, Form, Input} from 'antd';
import styles from './IntegrationsDetails.module.css';
import Editor from '@monaco-editor/react';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import {
  checkStatusPattern,
  sendLeadPattern,
} from '../../../utils/editorUtils';
import { integrationsApi, useLazyGetSharedByIntegrationQuery } from '../integrationsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import ShareButton from '../../../shared/ShareButton/ShareButton';
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {TemplateModal} from "./modal/TemplateModal";
import {EditOutlined} from "@ant-design/icons";

const { TextArea } = Input;

export const IntegrationsDetails = ({ integration, isDisabled, onFinish }) => {
  const [sendLead, setSendLead] = useState(
    integration ? integration.sendLead : sendLeadPattern()
  );
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const [checkStatus, setCheckStatus] = useState(
    integration ? integration.checkStatus : checkStatusPattern()
  );
  const [editorKey, setEditorKey] = useState(Date.now())
  const [isActive, setIsActive] = useState(
    integration ? integration.isActive : true
  );
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedTemplateIntegration, setSelectedTemplateIntegration] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [shared, setShared] = useState();
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const [trigger] = useLazyGetSharedByIntegrationQuery();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const [isOpenModalTemplate, setOpenModalIsTemplate] = useState(false);
  const [errorEditors, setErrorEditors] = useState({sendLead: null, checkStatus: null});

  const refetchShared = () => {
    trigger(integration.id)
      .then(res => {
        setShared(res.data)
      })
  }

  useEffect(() => {
    if (!integration) form.setFieldValue('isActive', true);
    if (integration) {
      refetchShared();
    }
  }, [integration]);

  useEffect(() => {
    if (integration) {
      form.setFieldsValue(integration);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(integration.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(integration.updatedAt, user.timezone)
      );
      form.setFieldValue('customer', {
        label: `#${integration.customer.id} ${integration.customer.name}`,
        value: integration.customer.id,
        key: integration.customer.id,
      });

      setIsActive(integration.isActive);
      if (integration?.customer?.brand?.id) {
        setSelectedBrand({
          label: `#${integration.customer.brand.id} ${integration.customer.brand.name}`,
          value: integration.customer.brand.id,
          key: integration.customer.brand.id,
        });
        form.setFieldValue('brand', {
          label: `#${integration.customer.brand.id} ${integration.customer.brand.name}`,
          value: integration.customer.brand.id,
          key: integration.customer.brand.id,
        });
      }
      if(integration?.template?.id) {
        setSelectedTemplateIntegration({
          label: `#${integration.template.id} ${integration.template.name}`,
          value: integration.template.id,
          key: integration.template.id,
        });
      }
      setSelectedCustomer({
        label: `#${integration.customer.id} ${integration.customer.name}`,
        value: integration.customer.id,
        key: integration.customer.id,
      });
      setSendLead(integration.sendLead);
      setCheckStatus(integration.checkStatus);
    }
  }, [integration]);

  const fetchDefaultIntegrations = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/template-integrations/label?filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchDefaultIntegrations = async (name) => {
    const res = await api.get(`/template-integrations/label?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchCustomers = async (name) => {
    const idFilter =
        !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
        `/customer/label?filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name} ${c?.brand?.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async (name) => {
    const res = await api.get(`/customer/label?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name} ${c?.brand?.name}`,
      value: c.id,
    }));
  };
  const onSuccessDefaultIntegration = (sendLead, checkStatus) => {
    setSendLead(sendLead)
    setCheckStatus(checkStatus)
    setOpenModalIsTemplate(false)
    form.setFieldValue("sendLead1-c", sendLead);
    form.setFieldValue("checkStatus-c", checkStatus);
    setErrorEditors({sendLead: null, checkStatus: null});
    setEditorKey(Date.now())
  }
  const handleCancelModalDefaultIntegration = () => {
    setOpenModalIsTemplate(false)
  }
  return (
    <>
      <TemplateModal onSuccess={onSuccessDefaultIntegration} defaultIntegrationId={selectedTemplateIntegration?.value}
                     isOpen={isOpenModalTemplate}
                     handleCancel={handleCancelModalDefaultIntegration} />
      <div className='flex justify-between'>
        {hasPermissions(user, PERMISSIONS.INTEGRATIONS_EDIT) && (
          <DeleteButton entity={integration} onFinish={onFinish} />
        )}
        <div>
          {integration &&
            <ShareButton shares={shared} entity={{ integration_id: integration.id }} refetch={refetchShared} />}
        </div>
      </div>
      <Form
        form={form}
        onFinish={() => {
          if(sendLead.includes('console.warn') || sendLead.includes('console.err')) {
            message.error(f({id: 'dont_use_console_log'}));
            return false
          }
          if(errorEditors.sendLead || errorEditors.checkStatus) {
            message.error(errorEditors.sendLead || errorEditors.checkStatus);
            return false
          }
          dispatch(integrationsApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue("name"),
            comment: form.getFieldValue("comment"),
            customerId: selectedCustomer?.value,
            templateId: selectedTemplateIntegration?.value || null,
            sendLead,
            checkStatus,
            isActive,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({ id: 'title' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'title' })} rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
          name="customer"
          label={f({ id: 'customer' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            className={styles.input}
            value={selectedCustomer}
            placeholder={f({ id: 'customer' })}
            initialFetch={initialFetchCustomers}
            fetch={fetchCustomers}
            onChange={(e) => {
              setSelectedCustomer(e);
            }}
          />
          {selectedCustomer?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.CUSTOMERS_EDIT} isShow={!!selectedCustomer?.value}
                                     onClick={() => {
                                       setIsOpenDrawer(true)
                                       setDataDrawer({id: selectedCustomer?.value, type: 'customers'})
                                     }}/>}
        </Form.Item>
        <Form.Item
            name="template-integration"
            label={f({ id: 'integration_template' })}
        >
          <AutocompleteSelect
              className={styles.input}
              allowClear
              value={selectedTemplateIntegration}
              placeholder={f({ id: 'integration_template' })}
              initialFetch={initialFetchDefaultIntegrations}
              fetch={fetchDefaultIntegrations}
              onChange={(e) => {
                setSelectedTemplateIntegration(e);
                if(e) {
                  setOpenModalIsTemplate(true)
                }
              }}
          />
          {selectedTemplateIntegration?.value && (<><PermissionalButtonDrawer permissions={PERMISSIONS.INTEGRATIONS_EDIT} isShow={!!selectedTemplateIntegration?.value}
                                                                onClick={() => {
                                                                  setIsOpenDrawer(true)
                                                                  setDataDrawer({id: selectedTemplateIntegration?.value, type: 'template-integrations'})
                                                                }}/>
            <Button onClick={() => setOpenModalIsTemplate(true)} style={{marginLeft: '5px'}} type="primary" icon={<EditOutlined />} /></>)}
        </Form.Item>
        <Form.Item name="comment" label={f({ id: 'comment' })}>
          <TextArea
            rows={4}
            placeholder={f({ id: 'comment' })}
            className={styles.input}
            style={{ resize: 'none' }}
          />
        </Form.Item>

        {integration && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {integration && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}

        <div className={styles.editorLabel}>{f({ id: 'sending_lead' })}</div>
        <Form.Item
          className={styles.editor}
          wrapperCol={{ offset: 1 }}
          labelCol={{ span: 24 }}
          layout="vertical"
          name="sendLead1-c"
        >
          <Editor
            key={editorKey}
            options={{
              minimap: { enabled: true },
              scrollbar: {
                handleMouseWheel: false,
                alwaysConsumeMouseWheel: false,
              },
            }}
            height="80vh"
            width="70vw"
            theme="vs-dark"
            defaultLanguage="javascript"
            onChange={setSendLead}
            defaultValue={sendLead}
            onValidate={(validator) => {
              console.log(validator, 'send-lead')
              setErrorEditors({sendLead: validator?.[0]?.message || null, checkStatus: errorEditors.checkStatus});
            }}
          />
        </Form.Item>

        <div className={styles.editorLabel}>{f({ id: 'check_status' })}</div>
        <Form.Item
          wrapperCol={{ offset: 1 }}
          labelCol={{ span: 24 }}
          layout="vertical"
          className={styles.editor}
          name="checkStatus-c"
        >
          <Editor
            key={editorKey}
            options={{
              minimap: { enabled: true },
              scrollbar: {
                handleMouseWheel: false,
                alwaysConsumeMouseWheel: false,
              },
            }}
            height="80vh"
            width="70vw"
            theme="vs-dark"
            onChange={setCheckStatus}
            defaultLanguage="javascript"
            value={checkStatus}
            defaultValue={checkStatus}
            onValidate={(validator) => {
              console.log(validator, 'check-status')
              setErrorEditors({checkStatus: validator?.[0]?.message || null, sendLead: errorEditors.sendLead})
            }}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            { f({id: 'save'}) }
          </Button>
        </Form.Item>
      </Form>
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </>
  );
};
