import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Popconfirm,
  Select, Tag, Tooltip,
} from 'antd';
import styles from './BuyersForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import {DeleteOutlined, PlusOutlined, UserSwitchOutlined} from '@ant-design/icons';
import { CreatePostbackModal } from './CreatePostbackModal';
import { buyersApi, useLazyGetSharedByBuyerQuery } from '../buyersApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import ShareButton from '../../../shared/ShareButton/ShareButton';
import {DrawerCustom} from "../../../shared/Drawer/Drawer";
import {PermissionalButtonDrawer} from "../../../shared/PermissionalLink/PermissionalLink";
import {camelToSnake} from "../../../utils/camelToSnake";
import {translateStatus} from "../../../utils/translateStatus";
import {useHandleLogout} from "../../../hooks/useHandleLogout";
import {useNavigate} from "react-router-dom";

export const BuyersForm = ({ userApi, isDisabled, onFinish, refetch }) => {
  const { formatMessage: f } = useIntl();
  const [selectedUser, setSelectedUser] = useState();
  const [activePostback, setActivePostback] = useState();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(true);
  const [isCanInject, setIsCanInject] = useState(false);
  const [isAllowedLogin, setIsAllowedLogin] = useState(true);
  const [isAllowedApi, setIsAllowedApi] = useState(true);
  const [isPostbackModalOpen, setIsPostbackModalOpen] = useState(false);
  const [timezonesList, setTimezonesList] = useState([]);
  const [timezone, setTimezone] = useState();
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [shared, setShared] = useState();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.globalSlice);
  const [trigger] = useLazyGetSharedByBuyerQuery();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOtpEnabled, setIsOtpEnabled] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({ id: null, type: ''});
  const navigate = useNavigate();

  const refetchShared = () => {
    trigger(userApi.id)
      .then(res => {
        setShared(res.data)
      })
  }

  useEffect(() => {
    if (userApi) {
      refetchShared();
    }
  }, []);

  useEffect(() => {
    if (userApi && user) {
      setTimezone(userApi.timezone);
      form.setFieldsValue(userApi);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(userApi.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(userApi.updatedAt, user.timezone)
      );
      setIsActive(userApi.isActive);
      form.setFieldValue('apiKey', userApi.apiKey);
      setIsAllowedLogin(userApi.isAllowedLogin);
      setIsAllowedApi(userApi.isAllowedApi);
      setIsCanInject(userApi.isCanInject);
      setIsOtpEnabled(userApi.otpEnabled);
      if (userApi?.user?.id) {
        setSelectedUser({
          label: `#${userApi.user.id} ${userApi.user.nickname}`,
          value: userApi.user.id,
          key: userApi.user.id,
        });
        form.setFieldValue('user', {
          label: `#${userApi.user.id} ${userApi.user.nickname}`,
          value: userApi.user.id,
          key: userApi.user.id,
        });
      }
      if (userApi?.brand?.id) {
        form.setFieldValue('brand_id', {
          label: `#${userApi.brand.id} ${userApi.brand.name}`,
          value: userApi.brand.id,
          key: userApi.brand.id,
        });
        setSelectedBrand({
          label: `#${userApi.brand.id} ${userApi.brand.name}`,
          value: userApi.brand.id,
          key: userApi.brand.id,
        });
        setSelectedPermissions({
          label: `#${userApi.permissions.id} ${userApi.permissions.name}`,
          value: userApi.permissions.id,
          key: userApi.permissions.id,
        });
      }
    } else if (user) {
      setSelectedUser({
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
      form.setFieldValue('user', {
        label: `#${user.id} ${user.nickname}`,
        value: user.id,
        key: user.id,
      });
    }
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
  }, [userApi, user]);

  const onDelete = (id) => {
    api.delete(`/postback/${id}`).then(() => {
      refetch();
    });
  };
  const onCanInjectChange = (e) => {
    setIsCanInject(e.target.checked);
  };
  const onAllowedLogin = (e) => {
    setIsAllowedLogin(e.target.checked);
  };
  const onAllowedApi = (e) => {
    setIsAllowedApi(e.target.checked);
  };

  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.nickname}`,
      value: c.id,
    }));
  };

  const fetchBrands = async (name) => {
    const idFilter =
        !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/brand/label?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchBrands = async (name) => {
    const res = await api.get(`/brand/label?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchPermissions = async (name) => {
    const idFilter =
        !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/buyer-permissions?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchPermissions = async (name) => {
    const res = await api.get(`/buyer-permissions?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const onOtpEnabled = (e) => {
    setIsOtpEnabled(e.target.checked)
  }

  const [handleLogout] = useHandleLogout();
  const { editingKey } = useSelector((state) => state.defaultPage);
  const loginAs = (record) => {
    api
        .post(`/auth/login-as/buyer/${record.id}`, {
          type: user.type,
          device: navigator.userAgent,
        })
        .then((res) => {
          handleLogout();
          localStorage.setItem('login_as', true);
          localStorage.setItem('access_token', res.data.access_token);
          localStorage.setItem('entry_type',  res.data.user_type);
          navigate('/');
          window.location.reload();
        });

    form.setFieldsValue(record);
  };

  return (
      <div>
        <div className="flex justify-between">
          <DeleteButton entity={userApi} onFinish={onFinish} />
          <div style={{display: "flex", gap: '5px'}}>
            {userApi && <ShareButton shares={shared} entity={{ buyer_id: userApi.id }} refetch={refetchShared} isSharingLeads />}
            { hasPermissions(user, PERMISSIONS.BUYERS_EDIT) && userApi && user.isAdmin && (
                <span
                    className="flex justify-center"
                >
                <Tooltip
                    title={`${f({id: 'login_as'})} ${userApi?.nickname}`}
                >
                  <Button
                      className="ml-2"
                      disabled={editingKey !== ''}
                      onClick={() => loginAs(userApi)}
                      shape="circle"
                      icon={<UserSwitchOutlined/>}
                  />
                </Tooltip>
        </span>)
            }
          </div>
        </div>
      <Form
        form={form}
        onFinish={() => {
          dispatch(buyersApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            user_id: selectedUser?.value || form.getFieldValue('user').value,
            isActive,
            isCanInject,
            isAllowedLogin,
            isAllowedApi,
            timezone,
            otpEnabled: isOtpEnabled,
            brand_id: selectedBrand?.value,
            buyer_permissions_id: selectedPermissions?.value,
            createdAt: undefined,
            updatedAt: undefined,
            user: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={ f({ id: 'name'}) }
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'name'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="nickname"
          label={f({ id: 'nickname'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'nickname'})} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              type: 'email',
            },
          ]}
        >
          <Input placeholder="email" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              message: f({ id: 'invalid_symbol'}, { s: '@'}),
              validator: (_, value, callback) => {
                let regex = /@/;
                if (regex.test(value)) {
                  callback('no error');
                } else {
                  callback();
                }
              },
            },
          ]}
          name="telegram"
          label="Telegram"
        >
          <Input placeholder="Telegram" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="password" label={f({ id: 'password' })}>
          <Input placeholder={f({ id: 'password' })} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="timezone"
          label={f({ id: 'timezone' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder={f({ id: 'timezone' })}
            style={{ maxWidth: 350 }}
            showSearch
            defaultValue={timezone}
            onChange={setTimezone}
            options={timezonesList.map((t) => ({
              value: t.timezone,
              label: t.name,
              key: t.timezone,
            }))}
          />
        </Form.Item>
        <Form.Item name="brand_id" label={f({ id: 'brand_of_purchase' })}
                   rules={[
                     {
                       required: true,
                     },
                   ]}>
          <AutocompleteSelect
              className={styles.input}
              value={selectedBrand}
              placeholder={f({ id: 'brand_of_purchase' })}
              initialFetch={initialFetchBrands}
              fetch={fetchBrands}
              allowClear={true}
              onChange={setSelectedBrand}
          />
          {selectedBrand?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.BRANDS_EDIT} isShow={!!selectedBrand?.value}
                                     onClick={() => {
                                       setIsOpenDrawer(true)
                                       setDataDrawer({id: selectedBrand?.value, type: 'brands'})
                                     }}/>}
        </Form.Item>
        {user.isAdmin && <Form.Item name="buyer_permissions_id" label={f({ id: 'permissions' })}
                   rules={[
                     {
                       required: true,
                     },
                   ]}>
          <AutocompleteSelect
              className={styles.input}
              value={selectedPermissions}
              placeholder={f({ id: 'permissions' })}
              initialFetch={initialFetchPermissions}
              fetch={fetchPermissions}
              allowClear={true}
              onChange={setSelectedPermissions}
          />
          {selectedPermissions?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.PERMISSIONS_EDIT} isShow={!!selectedPermissions?.value}
                                                             onClick={() => {
                                                               setIsOpenDrawer(true)
                                                               setDataDrawer({id: selectedPermissions?.value, type: 'buyerPermissions'})
                                                             }}/>}
        </Form.Item>}
        {hasPermissions(user, PERMISSIONS.USERS_READ) && (
          <Form.Item
            name="user"
            label={f({ id: 'responsible'})}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={selectedUser}
              placeholder={f({ id: 'user' })}
              initialFetch={initialFetchUsers}
              fetch={fetchUsers}
              onChange={setSelectedUser}
            />
            {selectedUser?.value && <PermissionalButtonDrawer permissions={PERMISSIONS.USERS_EDIT} isShow={!!selectedUser?.value}
                                       onClick={() => {
                                         setIsOpenDrawer(true)
                                         setDataDrawer({id: selectedUser?.value, type: 'users'})
                                       }}/>}
          </Form.Item>
        )}
        <Form.Item name="apiKey" label="apiKey">
          <Input
            disabled={true}
            placeholder="api-key"
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="externalId" label="externalId">
          <Input
              placeholder="externalId"
              rootClassName={styles.input}
          />
        </Form.Item>
        {userApi && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {userApi && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item name="isCanInject" label={f({ id: 'can_inject' })}>
          <Checkbox
            onClick={onCanInjectChange}
            defaultChecked={userApi?.isCanInject}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isAllowedLogin" label={f({ id: 'can_login' })}>
          <Checkbox
            onClick={onAllowedLogin}
            defaultChecked={userApi?.isAllowedLogin}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isAllowedApi" label={f({ id: 'can_use_api'})}>
          <Checkbox
            onClick={onAllowedApi}
            defaultChecked={userApi?.isAllowedApi}
            rootClassName={styles.input}
          />
        </Form.Item>
        {user?.isAdmin && userApi?.otpEnabled && (
            <Form.Item name="otpEnabled" label={'2FA'}>
              <Checkbox
                  onClick={onOtpEnabled}
                  defaultChecked={userApi?.otpEnabled}
                  rootClassName={styles.input}
              />
            </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            type="primary"
            loading={isDisabled}
            disabled={isDisabled}
            htmlType="submit"
          >
            {f({ id: 'save' })}
          </Button>
        </Form.Item>
      </Form>
      {userApi && (
        <div>
          <Divider />
          <h3 className="text-center">{f({ id: 'postback'})}</h3>
          <div className="flex items-center flex-wrap">
            {userApi?.postback?.map((p) => {
              return (
                <Card
                  key={p.id}
                  style={{ width: 500 }}
                  className="mx-8 my-2 cursor-pointer"
                  onClick={(e) => {
                    if (
                      e.target.innerText !== 'Ні' &&
                      e.target.innerText !== 'Так'
                    ) {
                      setActivePostback(p);
                      setIsPostbackModalOpen(true);
                    }
                  }}
                >
                  <div className="flex justify-between">
                    <div
                      className="flex items-center"
                      style={{
                        width: 300,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        display: 'flex',
                        alignItems: "center",
                      }}
                    >
                      <Popconfirm
                        title={f({ id: 'delete_postback'})}
                        description={f({ id: 'description_postback' })}
                        onConfirm={() => onDelete(p.id)}
                        okText={f({ id: 'yes' })}
                        cancelText={f({ id: 'no' })}
                      >
                        <DeleteOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="mx-2 text-red-500 text-lg leading-3"
                        />
                      </Popconfirm>

                      {p.link}
                    </div>
                    {p.status?.length ? (
                      <StatusTag style={{ maxHeight: 25 }} status={p.status} />
                    ) : (<Tag style={{maxHeight: '25px'}} color={'while'}>{f({ id: camelToSnake(p.action) })} </Tag>)
                    }
                  </div>
                </Card>
              );
            })}
            <Button
              className="mx-8"
              type="primary"
              onClick={() => {
                setActivePostback(null);
                setIsPostbackModalOpen(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
          <CreatePostbackModal
            onCancel={() => setIsPostbackModalOpen(false)}
            isOpen={isPostbackModalOpen}
            pb={activePostback}
            refetch={() => {
              refetch();
              setIsPostbackModalOpen(true);
            }}
            userId={userApi.id}
          />
        </div>
      )}
      <DrawerCustom open={isOpenDrawer} setOpen={setIsOpenDrawer} data={dataDrawer} />
    </div>
  );
};
