import { Button, Form, Input } from 'antd';
import styles from './BrandsForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import {
  hasPermissions
} from '../../../hooks/usePermissionsAllowed';
import { brandsApi, useLazyGetSharedByBrandQuery } from '../brandsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import ShareButton from '../../../shared/ShareButton/ShareButton';

export const BrandsForm = ({ brand, isDisabled, onFinish }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(
    brand?.isActive ? brand.isActive : true
  );
  const [shared, setShared] = useState();
  const [trigger] = useLazyGetSharedByBrandQuery();

  const refetchShared = () => {
    trigger(brand.id)
      .then(res => {
        setShared(res.data)
      })
  }


  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    if (brand) {
      form.setFieldsValue(brand);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(brand.createdAt, user?.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(brand.updatedAt, user?.timezone)
      );
      setIsActive(brand.isActive);

      refetchShared();
    }
  }, [brand]);

  return (
    <>
      <div className='flex justify-between'>
        {hasPermissions(user, PERMISSIONS.BRANDS_EDIT) && (
          <DeleteButton entity={brand} onFinish={onFinish} />
        )}
        <div>
          {brand &&
            <ShareButton shares={shared} entity={{ brand_id: brand.id }} refetch={refetchShared} />}
        </div>
      </div>
      <Form
        form={form}
        onFinish={() => {
          dispatch(brandsApi.util.resetApiState());
          onFinish({
            ...form.getFieldsValue(),
            isActive,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({ id: 'title' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({ id: 'title' })} rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="domain"
          label={f({ id: 'domain' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input
            placeholder="https://domain.com"
            rootClassName={styles.input}
          />
        </Form.Item>

        <Form.Item
          name="serverIp"
          label={f({ id: 'server' })}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="1.1.1.1" rootClassName={styles.input} />
        </Form.Item>

        {brand && (
          <Form.Item name="createdAt" label={f({ id: 'created' })}>
            <Input
              disabled
              placeholder={f({ id: 'created' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {brand && (
          <Form.Item name="updatedAt" label={f({ id: 'updated' })}>
            <Input
              disabled
              placeholder={f({ id: 'updated' })}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit" disabled={isDisabled}>
            {f({ id: 'save' })}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
