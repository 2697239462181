import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';
import styles from './PermissionsForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';
import { permissionsApi } from '../permissionsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';

import {useIntl} from "react-intl";
import { useNavigate } from 'react-router-dom';
import { CopyOutlined } from '@ant-design/icons';

export const PermissionsForm = ({ permissions, isDisabled, onFinish, isExistingOrder = false }) => {
  const { formatMessage: f } = useIntl();
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(
    permissions ? permissions.isActive : true
  );
  const entities = [
    { title: f({id: 'lead_menu'}), value: 'leads' },
    { title: f({id: 'order'}), value: 'orders' },
    { title: f({id: 'box_menu'}), value: 'boxes' },
    { title: f({id: 'integrations_menu'}), value: 'integrations' },
    { title: f({id: 'Customers'}), value: 'customers' },
    { title: f({id: 'call_statuses'}), value: 'callStatuses' },
    { title: f({id: 'call_status_groups'}), value: 'callStatusesGroup' },
    { title: f({id: 'country_boxes'}), value: 'countryBoxes' },
    { title: f({id: 'brands_menu'}), value: 'brands' },
    { title: f({id: 'users_menu'}), value: 'users' },
    { title: f({id: 'webs_menu'}), value: 'buyers' },
    { title: f({id: 'teams_menu'}), value: 'teams' },
    { title: f({id: 'permissions'}), value: 'permissions' },
    { title: f({id: 'logs'}), value: 'logs' },
    { title: f({id: 'domains_menu'}), value: 'domains' },
    { title: f({id: 'offers_menu'}), value: 'offers' },
    { title: f({id: 'blacklists_menu'}), value: 'blacklists' },
    { title: 'KPI', value: 'kpi' },
  ];

  const scopeOptions = [
    { label: f({id: 'only_your'}), value: 'OWN' },
    { label: f({id: 'just_your_own_team'}), value: 'TEAM' },
    { label: f({id: 'own_and_child_teams'}), value: 'NESTED' },
  ];
  const usersScopeOptions = [
    { label: f({id: 'just_your_own_team'}), value: 'TEAM' },
    { label: f({id: 'own_and_child_teams'}), value: 'NESTED' },
  ];
  const scopeAccess = ['teams', 'logs', 'blacklists', 'kpi'];
  const [leadsOptions, setLeadsOptions] = useState([]);
  const [selectedLeadsOptions, setSelectedLeadsOptions] = useState([]);
  const [selectedOtherOptions, setSelectedOtherOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [selectedOrderOptions, setSelectedOrderOptions] = useState([]);
    const [selectedEntities, setSelectedEntities] = useState({
    leads: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    orders: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    boxes: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    users: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    integrations: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    countryBoxes: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    callStatuses: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    callStatusesGroup: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    customers: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    teams: {
      read: false,
      edit: false,
      scope: 'TEAM',
    },
    logs: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    brands: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    domains: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    offers: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    permissions: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    buyers: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
    blacklists: {
      read: false,
      edit: false,
      scope: 'OWN',
    },
      kpi: {
        read: true,
        edit: false,
        scope: 'OWN',
      },
  });
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.globalSlice);
  useEffect(() => {
    if (!permissions) form.setFieldValue('isActive', true);

    api.get('/lead/columns').then((res) => 
      { 
        const leadOptions = res.data.filter(item => item !== 'fraudRange')
        leadOptions.push('customer')
        leadOptions.push('responsible_affiliate')
        setLeadsOptions(leadOptions)
      });
    api.get('/order/columns').then((res) => setOrderOptions(res.data));
  }, []);

  const onChangeOrders = (e) => {
    setSelectedOrderOptions(e);
      };

  const onChangeOther = (e) => {
    setSelectedOtherOptions(e);
  };

  const onChangeLeads = (e) => {
    setSelectedLeadsOptions(e);
    };

  const getEntityValue = (entity) => {
    const entities = JSON.parse(permissions.entities);
    if (!permissions) return 'none';
    if (entities[entity].edit) return 'edit';
    if (entities[entity].read) return 'read';
  };

  const getEntityScope = (entity) => {
    const entities = JSON.parse(permissions.entities);
    return scopeOptions.find(
      (option) => entities[entity].scope === option.value
    )?.value;
  };

  useEffect(() => {
    if (permissions) {
      form.setFieldsValue(permissions);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(permissions.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(permissions.updatedAt, user.timezone)
      );
      setIsActive(permissions.isActive);
      const selectedLeads = JSON.parse(permissions.columns).leads;
      const selectedOrders = JSON.parse(permissions.columns).orders;
      const other = JSON.parse(permissions.other);
      const selectedOther = Object.keys(other).filter((k) => other[k].allowed);
      form.setFieldValue('leads', selectedLeads);
      form.setFieldValue('orders', selectedOrders);
      form.setFieldValue('other', selectedOther);
      setSelectedOrderOptions(selectedOrders);
      setSelectedOtherOptions(selectedOther);
      setSelectedLeadsOptions(selectedLeads);
      const entities = JSON.parse(permissions.entities);
      setSelectedEntities(entities);
            for (const key of Object.keys(entities)) {
        const entityValue = getEntityValue(key);
        const scopeValue = getEntityScope(key);
        form.setFieldValue(`${key}_action`, entityValue);
        form.setFieldValue(`${key}_scope`, scopeValue);
      }
    }
  }, [permissions]);

  const onEntityActionChange = (e, entity) => {
    if (e === 'read') {
      setSelectedEntities({
        ...selectedEntities,
        [entity]: { ...selectedEntities[entity], read: true, edit: false },
      });
      return;
    }

    if (e === 'edit') {
      setSelectedEntities({
        ...selectedEntities,
        [entity]: { ...selectedEntities[entity], read: true, edit: true },
      });
      return;
    }

    setSelectedEntities({
      ...selectedEntities,
      [entity]: { ...selectedEntities[entity], read: false, edit: false },
    });
  };

  const onEntityScopeChange = (e, entity) => {
    setSelectedEntities({
      ...selectedEntities,
      [entity]: { ...selectedEntities[entity], scope: e },
    });
  };

  const CopyButton = ({ id }) => {
   
     const navigate = useNavigate();
    return (
      <>
        <div>
          <Tooltip placement="bottom" title={f({id: 'tooltip_duplicate'})}>
            <Button 
              style={{ minWidth: '140px', marginTop: '15px' }} type="primary" success
              onClick={() => navigate(`/permissions/duplicate/${id}`)} 
            >
              {f({id: 'duplicate'})}
              <CopyOutlined  className="text-base" />
            </Button>
          </Tooltip>
        </div>
      </>
    );
  };

  return (
    <>
      {hasPermissions(user, PERMISSIONS.PERMISSIONS_EDIT) && isExistingOrder && (
        <div>
          <DeleteButton entity={permissions} onFinish={onFinish} />
          <CopyButton id={permissions?.id}/>
        </div>
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(permissionsApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue('name')?.trim(),
            createdAt: undefined,
            updatedAt: undefined,
            leads: selectedLeadsOptions,
            orders: selectedOrderOptions,
            other: selectedOtherOptions.reduce((acc, curr) => {
              return {
                ...acc,
                [curr]: {
                  allowed: true,
                  scope: 'TEAM',
                },
              };
            }, {}),
            entities: selectedEntities,
            isActive,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        {permissions && isExistingOrder && (
          <Form.Item name="createdAt" label={f({id: 'created'})}>
            <Input
              disabled
              placeholder={f({id: 'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {permissions && isExistingOrder && (
          <Form.Item name="updatedAt" label={f({id: 'updated'})}>
            <Input
              disabled
              placeholder={f({id: 'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Divider orientation="center">{f({id: 'entities'})}</Divider>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {entities.map((e) => (
            <Card
              size="small"
              title={e.title}
              style={{
                width: 300,
                margin: 10,
              }}
            >
              <Form.Item name={`${e.value}_action`} label={f({id: 'allowed'})}>
                <Select
                  options={[
                    { label: f({id: 'nothing'}), value: 'none' },
                    { label: f({id: 'reading'}), value: 'read' },
                    { label: f({id: 'edit'}), value: 'edit' },
                  ]}
                  defaultValue={'Нічого'}
                  onChange={(event) => onEntityActionChange(event, e.value)}
                />
              </Form.Item>
              {scopeAccess.includes(e.value) || (
                <Form.Item name={`${e.value}_scope`} label={f({id: 'visibility'})}>
                  <Select
                    options={e.value === 'users' ? usersScopeOptions : scopeOptions}
                    onChange={(event) => onEntityScopeChange(event, e.value)}
                  />
                </Form.Item>
              )}
            </Card>
          ))}
        </div>
        <Divider orientation="center">{f({id: 'columns'})}</Divider>
        <Row className="flex justify-center">
          <div className="flex">
            <div className="mr-2">{f({id: 'lead_menu'})}:</div>
            <Checkbox.Group
              value={selectedLeadsOptions}
              options={leadsOptions}
              onChange={onChangeLeads}
            />
          </div>
          <div className="flex">
            <div className="mr-2">{f({id: 'order'})}:</div>
            <Checkbox.Group
              value={selectedOrderOptions}
              options={orderOptions}
              onChange={onChangeOrders}
            />
          </div>
        </Row>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
