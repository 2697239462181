import { Button, Form, Input } from 'antd';
import styles from './DirectionForm.module.css';
import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { hasPermissions } from '../../../hooks/usePermissionsAllowed';
import { directionsApi } from '../directionsApi';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from '../../../shared/DeleteButton/DeleteButton';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const DirectionForm = ({ group, isDisabled, onFinish }) => {
  const [form] = Form.useForm();
  const [selectedDirection, setSelectedDirection] = useState();
  const [isActive, setIsActive] = useState(
    group?.isActive !== undefined ? group?.isActive : true
  );
  const { formatMessage: f } = useIntl();
  const { user } = useSelector((state) => state.globalSlice);
  const dispatch = useDispatch();
  const { TextArea } = Input;
  useEffect(() => {
    if (group) {
      form.setFieldsValue(group);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(group.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(group.updatedAt, user.timezone)
      );
      setIsActive(group.isActive);
    }
  }, [group]);


  return (
    <>
      {hasPermissions(user, PERMISSIONS.OFFERS_EDIT) && (
        <DeleteButton entity={group} onFinish={onFinish} />
      )}
      <Form
        form={form}
        onFinish={() => {
          dispatch(directionsApi.util.resetApiState());
          onFinish({
            name: form.getFieldValue('name')?.trim(),
            isActive: isActive ? isActive : false,
            createdAt: undefined,
            updatedAt: undefined,
          });
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label={f({id: 'title'})}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder={f({id: 'title'})} rootClassName={styles.input} />
        </Form.Item>
        {group && (
          <Form.Item name="createdAt" label={f({id:'created'})}>
            <Input
              disabled
              placeholder={f({id:'created'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {group && (
          <Form.Item name="updatedAt" label={f({id:'updated'})}>
            <Input
              disabled
              placeholder={f({id:'updated'})}
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button
            loading={isDisabled}
            disabled={isDisabled}
            type="primary"
            htmlType="submit"
          >
            {f({id: 'save'})}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
