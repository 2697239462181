import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { CustomersForm } from '../CustomersForm/CustomersForm';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";

export const EditCustomer = ({drawerId}) => {
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const [customer, setUser] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.CUSTOMERS_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    api.get(`/customer/${reqId}`).then((res) => {
      setUser({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedUser) => {
    setIsDisabled(true);
    delete updatedUser.updatedAt;
    api
      .put(`/customer/${reqId}`, {
        ...updatedUser,
      })
      .then((_) => {
        if(drawerId){
          navigate(-1);
        }

        message.success(f({id: 'customer_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  return (
    <CustomersForm
      isDisabled={isDisabled}
      customer={customer}
      onFinish={onFinish}
    />
  );
};
