import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import api from '../../../api/api';
import { BuyersForm } from '../BuyersForm/BuyersForm';
import { App, Tabs } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { PERMISSIONS } from '../../../utils/permissions';
import {useIntl} from "react-intl";
import { OrdersTab } from './OrdersTab';

export const EditBuyer = ({ drawerId }) => {
  const { formatMessage: f } = useIntl();
  const { message } = App.useApp();
  const [user, setUser] = useState();
  const { id } = useParams();
  usePermissionsAllowed(PERMISSIONS.BUYERS_EDIT);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [reqId] = useState(drawerId ? drawerId : id);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get(`/buyer/${reqId}`).then((res) => {
      setUser({
        ...res.data,
      });
      setIsLoading(false);
    });
  };

  const onFinish = (updatedUser, isOrdersTab) => {
    delete updatedUser.updatedAt;
    setIsDisabled(true);
    api
      .put(`/buyer/${reqId}`, {
        ...updatedUser,
        isOrdersTab: isOrdersTab
      })
      .then((_) => {
        if (!isOrdersTab || drawerId) {
          navigate(-1);
        }
        message.success(f({ id: 'webmaster_updated'}));
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      })
      .finally(() => {
        setIsDisabled(false);
      });
  };

  if (isLoading) return 'loading';

  const items = [
    {
      label: f({ id: 'main_info'}),
      key: 'item-1',
      children: <BuyersForm
        userApi={user}
        isDisabled={isDisabled}
        onFinish={onFinish}
        refetch={getData}
      />
    },
    {
      label: f({ id: 'orders'}),
      key: 'item-2',
      children: <OrdersTab drawerId={drawerId} buyer={user} onFinish={onFinish} isDisabled={isDisabled} />
    },
  ];
  return <Tabs items={items} defaultActiveKey={'item-2'} />;
};
